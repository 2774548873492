import React from 'react'
import Header from '../Home/HomeComponents/Header/Header';
import About from './HomeComponents/About/About';
import Caracteristicas from './HomeComponents/Caracteristicas/Caracteristicas';
import Herramientas from './HomeComponents/Herramientas/Herramientas';
import Contacto from './HomeComponents/Contacto/Contacto'; 
import './Home.css'; 
import Planes from './HomeComponents/Planes/Planes';
function Home() {
    return (
        <div className="divhome">
            <Header/> 
            <Caracteristicas/>
            <About/>
            <Herramientas/>
            <Planes/>
            <Contacto/>
        </div>
    )
}

export default Home
