import {createStore,combineReducers,applyMiddleware,compose} from 'redux';
import thunk from 'redux-thunk';
import userReducer from './reducers/userReducer';
import dataReducer from './reducers/datareducer'; 
import uiReducer from './reducers/uireducer';
import dataSalesReducer from './reducers/datasalesreducer';
import dataasistencialreducer from './reducers/dataasistencialreducer';
import dataInfraReducer from './reducers/dataInfraReducer';


const initialState ={}; 

const middleware=[thunk];

const reducers= combineReducers({
    user:userReducer, 
    data:dataReducer,
    UI:uiReducer,
    dataSales:dataSalesReducer,
    dataAsistencial:dataasistencialreducer,
    datainfra:dataInfraReducer
});
const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

    const enhancer = composeEnhancers(applyMiddleware(...middleware));

    const store = createStore(reducers, initialState, enhancer);
    
export default store;
