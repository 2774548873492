import React from 'react';
import './Header.css';
import {Link} from 'react-router-dom';
import Button from '../../../Elements/Button';
import imgsrc from './logovertical.svg'

function Header() { 
    return (
       
        <div className='main-header'>

       
        <div className="header-container">

            <div className='header-flex-1'>
                <h1 className='h1-header'>
                    ÚNETE
                </h1>

                <h2 className='h2-header'>Al Primer Ecosistema Digital</h2>
                <h2 className='h2-header'>De Ingeniería Clínica</h2>

                <h3 className='h3-header'>Haz Parte del Futuro</h3>
            </div>

        <div className="header-flex-2">
                     <img src={imgsrc} alt="logo" className='logoheader'/>
                    
                 
                    
                    <Link className="linkbtn" to="/login">
                    <Button buttonSize="btn--wide" buttonColor='blue'>
                       Ingresar
                    </Button>
                    </Link>
                    
                    </div>
       

        </div>

        </div>
 
    )
}

export default Header
