import React,{ useState, useEffect } from 'react';
import PropTypes from 'prop-types'; 
import Button from '../../Elements/Button'
import { Link } from 'react-router-dom';
import './Topbar.css';
import { FaBars, FaTimes } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import imgsrc from '../../Navbar/logohorizontal.svg';
import {connect} from 'react-redux';
import {logoutDashboard} from '../../../redux/actions/useractions';
import MyButton from '../../../utils/MyButton';

import { FaPowerOff } from "react-icons/fa";




function Topbar(props) {
    const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {showButton()}, []);

  window.addEventListener('resize', showButton);

  const handleLogout=()=>{
    props.logoutDashboard(props.history); 
  }
    return (
        <div>
            <>
      <IconContext.Provider value={{ color: '#fff' }}>
        <nav className='navbar'>
          <div className='navbar-container container'>
            <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            
            <img src={imgsrc} alt="logo" className='logonavbar'/>
            
            </Link> 
            <div className='menu-icon' onClick={handleClick}>
              {click ? <FaTimes color="#03178C"/> : <FaBars color="#03178C"/>}
            </div>
             <ul className={click ? 'nav-menu active' : 'nav-menu'}>
              
              
             
              <li className='nav-btn'>
                {button ? (
                <MyButton tip="Cerrar Sesión" onClick={handleLogout} >
                <FaPowerOff color="#03178C"className="icon-logout"/>
                </MyButton>
                ) : (
                  <Link to='/login' className='btn-link'>
                    <Button
                      buttonStyle='btn--outline'
                      buttonSize='btn--mobile'
                      onClick={closeMobileMenu}
                    >
                     Ingresar
                    </Button>
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </nav>
      </IconContext.Provider>
    </>
            
        </div>
    )
}

const mapStateToProps = (state)=>({
    user:state.user
})
const mapActionsToProps={logoutDashboard};

Topbar.propTypes = {
    logoutDashboard:PropTypes.func.isRequired,   
    user: PropTypes.object.isRequired
}

export default connect(mapStateToProps,mapActionsToProps)(Topbar)







