import React from 'react'
import './Caracteristicas.css'
import { FaUsers,FaFileMedicalAlt,FaShoppingCart,FaChartLine} from 'react-icons/fa';

function Caracteristicas() {
    return (
        <div id="caracteristicas" className="text-center">
             <div className="caract-titulo"> 
             <h1 className="caract-titulo-h1">Somos Más Que un Software de Mantenimiento</h1>
             <hr className="separadorgradiente"></hr>
             </div>

            <div className="caract-container">
           
            <div className="row">
            <div className="caracter">
            <FaFileMedicalAlt className="caract-icono"/>
            
            <h3 className="titulo-caracteristica">Documentación</h3>
            <p className="caract-descripcion">Genera fichas tecnicas, reportes de mantenimiento y cronogramas en la nube.</p>
            </div>
            <div className="caracter">
            <FaShoppingCart className="caract-icono"/>
            <h3 className="titulo-caracteristica">Marketplace</h3>
            <p className="caract-descripcion">Adquiere equipos, insumos o repuestos con proveedores certificados a tan solo un clic.</p>
           
            </div>
            <div className="caracter">
            <FaChartLine className="caract-icono"/>
            <h3 className="titulo-caracteristica">Gestión</h3>
            <p className="caract-descripcion">Gestiona diferentes Consultorios y Hospitales con indicadores en tiempo real.</p>
           
            </div>

            <div className="caracter">
            <FaUsers className="caract-icono"/>
            <h3 className="titulo-caracteristica">Comunidad</h3>
            <p className="caract-descripcion">Haz parte de una grandiosa red de profesionales, conéctate, capacitate y crece.</p>
           
            </div>
            
            </div>

            </div>
            
            
        </div>
    )
}

export default Caracteristicas
