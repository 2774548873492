import React from 'react';
import './Herramientas.css';
import { FaChartBar,FaRegHospital,FaCogs,FaCloud,FaHandsHelping,FaDiagnoses} from 'react-icons/fa';
function Herramientas() {
    return (
        <div id="herramientas" className="text-center">
           <div className="containerherramientas">
               <div className="secciontitulo">
                <h1 className="tituloherramientas">
                Herramientas
                </h1>
                <hr className="separadorherramientas"/>
                <p className="parrafoherramientas">Sé un motor de cambio en la ingeniería clínica del futuro.</p>
               </div>

               <div className="flex-container-herramientas">
                   <div className="row-herramientas">
                       <div className="div-herramienta">
                       <FaChartBar className="caract-herramientas"/>
                       <h3>Gestión en Linea</h3>
                       <p className="desc-herramientas">A través de herramientas fáciles gestiona la tecnología biomédica de acuerdo al riesgo, optimiza los recursos y alcanza la eficiencia del uso de tus equipos.</p>
                       </div>
                       <div className="div-herramienta">
                       <FaRegHospital className="caract-herramientas"/>
                       <h3>Biomedical Marketplace</h3>
                       <p className="desc-herramientas">Encuentra equipos, accesorios y repuestos en un mercado en linea con proveedores confiables al mejor precio y de una manera rápida</p>
                       </div>
                       <div className="div-herramienta">
                       <FaCogs className="caract-herramientas"/>
                       <h3>Decisiones Inteligentes</h3>
                       <p className="desc-herramientas">Apoyamos tus procesos con un motor de inteligencia artificial, esto permitirá que tomes las mejores decisiones según tus indicadores.</p>
                       </div>
                       
                       
                   </div>
                   <div className="row-herramientas">
                   <div className="div-herramienta">
                       <FaCloud className="caract-herramientas"/>
                       <h3>Documentación en la Nube</h3>
                       <p className="desc-herramientas">Centraliza la documentación de tu tecnología en la nube con nuestro almacenamiento gratuito y accede desde cualquier lugar.</p>
                       </div>
                       <div className="div-herramienta">
                       <FaHandsHelping className="caract-herramientas"/>
                       <h3>Comunidad</h3>
                       <p className="desc-herramientas">Participa en foros, resuelve dudas, capacitate y mejora tus habilidades haciendo parte de nuestra comunidad de ingeniería biomédica.</p>
                       </div>
                       <div className="div-herramienta">
                       <FaDiagnoses className="caract-herramientas"/>
                       <h3>Indicadores en Tiempo Real</h3>
                       <p className="desc-herramientas">Obten indicadores que te permitan monitorear el estado de tus equipos en tiempo real para mejorar tus procesos y obtener mejores resultados.</p>
                       </div>
                       
                   </div>
               </div>

           </div>
        </div>
    )
}

export default Herramientas
