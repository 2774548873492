import React, { Component, Fragment } from 'react';
import { forwardRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {getListaCalFiltrado, getListaCal,clearErrors,deleteProgCalTabla,postCalibracionTabla, editProgCalTabla} from '../../../redux/actions/dataactions';
import MaterialTable from 'material-table';

import MyButton from '../../../utils/MyButton';

import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';

import { FaArrowLeft, FaCheck, FaCheckCircle, FaClipboardList, FaFilePdf, FaPlus, FaUpload} from "react-icons/fa";
import CronogramaCalAuto from './CronogramaCalAuto';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core';
import moment from 'moment';
import Button from '../../Elements/Button';
import TablaCronogramaCalInd from './TablaCronogramaCalInd';
export class TablaCronogramaCal extends Component {
  constructor(){
    super();
    this.state={
        horainicio:'',
        horafinal:'',
        fecha:'',
        numcertificado:'',
        errors:{},
        aprobado:{},
        open:false,
        opendelete:false,
        progid:'',
        equipoid:'',
        clienteid:'',
        selectedFile: null,
    };
    
};
    
      componentDidMount(){
        this.props.getListaCalFiltrado(this.props.clienteId, "01/01/1950", "01/01/2035");
          
        }
  
  
      
        handleFiltrar = () => {
          console.log(this.state.fechaFin, this.state.fechaFin)
          this.props.getListaCalFiltrado(this.props.clienteId, moment(this.state.fechaInicio).format('DD/MM/YYYY'), moment(this.state.fechaFin).format('DD/MM/YYYY'))
        }


      static getDerivedStateFromProps(props, state) {
        const newState = {};
    
        if (props.UI.errors) {
            newState.errors = props.UI.errors;
        }
    
        if (props.UI.aprobado) {
           newState.aprobado = props.UI.aprobado;
        }
    
        return Object.keys(newState).length > 0 ? newState : null;
    }

      handleOpenDelete=(rawdataprogid,rawdataequipoid,rawdataclienteid)=>{
        console.log(rawdataequipoid);
        this.setState({opendelete:true,progid:rawdataprogid,equipoid:rawdataequipoid,clienteid:rawdataclienteid});
       
        };
      handleCloseDelete=()=>{
      this.setState({opendelete:false,errors:{}});
      };

      handleDelete=()=>{
      const {credentials}=this.props.user;

      if(credentials.tipouser==="Tecnico"){
        console.log("Calibración Borrado desde:"+credentials.tipouser)
        console.log("Perfil de Ingenieria"+credentials.handleuser)
        //mandar el user de ingenieria
        this.props.deleteProgCalTabla(this.state.progid,credentials.handleuser,this.props.clienteId);
      
      }else{
              //mandar el handle user normal 
              console.log("Calibración borrada desde:"+credentials.tipouser)
              console.log("Perfil de Ingenieria"+credentials.handle)
                //mandar el user de ingenieria
        this.props.deleteProgCalTabla(this.state.progid,credentials.handle,this.props.clienteId);
             // this.props.postMtoTabla(newMtoPreventivo,this.state.equipoid,this.props.clienteId,this.state.progid,credentials.handle); 
      
            }
      
      this.handleCloseDelete();
      }

      handleOpen=(rawdataprogid,rawdataequipoid,rawdatafecha,rawdatahora,rawdataidinv)=>{
      this.setState({open:true,progid:rawdataprogid,equipoid:rawdataequipoid,fecha:rawdatafecha,horainicio:rawdatahora,numcertificado:rawdataidinv});
      };

      handleClose=()=>{

        if(this.state.aprobado.mensaje){
          
         this.handleEditProgCal(this.state.progid);
          this.setState({open:false,errors:{},aprobado:{}});
       }else{
        this.setState({open:false,errors:{},aprobado:{}});
       }
      
       };


       handleEditProgCal=()=>{

        this.props.editProgCalTabla({nuevoestado:'Realizado'},this.state.progid,this.props.clienteId); 
     
      }      
       handlePostCal=()=>{
        const newCalibracion={
          fecha:this.state.fecha,
          horainicio:this.state.horainicio,
          horafinal:this.state.horafinal,
          numcertificado:this.state.numcertificado           
      };

        console.log(newCalibracion);
        const {credentials}=this.props.user;

      if(credentials.tipouser==="Tecnico"){
        console.log("Mantenimiento enviado desde:"+credentials.tipouser)
        console.log("Perfil de Ingenieria"+credentials.handleuser)
        //mandar el user de ingenieria
       // this.props.postMto(newMtoPreventivo,this.props.equipoId,this.props.clienteId,credentials.handleuser); 
       this.props.postCalibracionTabla(newCalibracion,this.props.clienteId,this.state.equipoid,this.state.progid,credentials.handleuser); 
      // this.props.postMtoTabla(newMtoPreventivo,this.state.equipoid,this.props.clienteId,this.state.progid,credentials.handleuser); 
            }else{
              //mandar el handle user normal 
              console.log("Mantenimiento enviado desde:"+credentials.tipouser)
              console.log("Perfil de Ingenieria"+credentials.handle)
              this.props.postCalibracionTabla(newCalibracion,this.props.clienteId,this.state.equipoid,this.state.progid,credentials.handle); 
             // this.props.postMtoTabla(newMtoPreventivo,this.state.equipoid,this.props.clienteId,this.state.progid,credentials.handle); 
      
            }
     

     


     
        }

      handleChange=(event)=>{
      this.setState({ [event.target.name]:event.target.value});
      };





    render() {
        const {equiposcal,loadingcal}= this.props.data;
       
        const {errors}=this.state;
        const { UI:{loading}}=this.props; 

        const tableIcons = {
            Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
            Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
            Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
            DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
            Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
            Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
            FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
            LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
            NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
            PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
            ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
            Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
            SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
            ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
            ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
          };

        const columns=[
          {title:'Id Inventario',field:'idinventario'},
            {title:'Nombre',field:'nombre'},
            {title:'Marca',field:'marca'},
            {title:'Serie',field:'serie'},
            {title:'Modelo',field:'modelo'},
            {title:'Ubicación',field:'ubicacion'},
            {title:'Fecha',field:'fecha'},
            {title:'Mes',field:'mesnombre'},
            {title:'Estado',field:'estado'},
            {title:'Lugar',field:'lugar'}


          
        
            
        ]
        
        let mapeoequipos=!loadingcal?(
        <div>
           <div className='div-container-links-inventario'>
            <CronogramaCalAuto clienteId={this.props.clienteId}></CronogramaCalAuto>
            <TablaCronogramaCalInd clienteId={this.props.clienteId}></TablaCronogramaCalInd>
           <Fragment>
                        <Link className='div-card-link' to={`/dashboard/perfilcliente/${this.props.clienteId}/cronocalperfil/uploadcal`}  state={equiposcal} >
                          <div className="dashing-cardcontent">
                                              <div className="dashing-cardtext">
                                                  <p className="dashing-text-card">
                                                Subir Reportes 
                                                  </p>
                              
                                              </div>
                                              <div className="dashing-cardicon">
                                                  <FaUpload color="#FFFFFF" className="dashing-icon"/>
                                              </div>
                                          </div>


                        </Link>
                        </Fragment>

           <Fragment>
                        <Link className='div-card-link' to={`/dashboard/perfilcliente/cronogramacal/${this.props.clienteId}`}  state={equiposcal} >
                          <div className="dashing-cardcontent">
                                              <div className="dashing-cardtext">
                                                  <p className="dashing-text-card">
                                                 Ver en PDF
                                                  </p>
                              
                                              </div>
                                              <div className="dashing-cardicon-green">
                                                  <FaFilePdf color="#FFFFFF" className="dashing-icon"/>
                                              </div>
                                          </div>


                        </Link>
                        </Fragment>
           </div>


           <div className='div-container-tecno-prestador'>
          <div className="input_divido_tecno_cliente">
            <div className="input_divido_children_tecno">
              <TextField className="login-input-email"
                required
                type="date"
                name="fechaInicio"
                id="fechaInicio"
                label="Desde"
                value={this.state.fechaInicio}
                onChange={this.handleChange}
                fullWidth
                helperText={errors.fechaInicio}
                error={errors.fechaInicio ? true : false}
                InputLabelProps={{
                  shrink: true,
                }}
                
              />
            </div>
            <div className="input_divido_children_tecno">
              <TextField className="login-input-email"
                required
                type="date"
                name="fechaFin"
                id="fechaFin"
                label="Hasta"
                value={this.state.fechaFin}
                onChange={this.handleChange}
                fullWidth
                helperText={errors.fechaFin}
                error={errors.fechaFin ? true : false}
                InputLabelProps={{
                  shrink: true,
                }}
                
              />
            </div>
            <div className='input_divido_tecno_boton'>
              <Button type="submit" buttonSize="btn--medium" buttonColor='blue' onClick={this.handleFiltrar}>
                Filtrar</Button>
            </div>
          </div>



        </div>
        <MaterialTable
        columns={columns}
        data={equiposcal}
        title=''
        icons={tableIcons}
        options={{
          cellStyle: {
              fontSize: 11,
              fontWeight:500,
              color:'#696B6D'
            
           
            },
            headerStyle: {
              fontSize: 12,
              fontWeight: 'bolder',
              color:"#03178c" // Ajusta el tamaño de fuente según tus necesidades
            }
        
        }}
        
        actions={[
          rowData => ({
            icon: () =>  <Link to={`/dashboard/perfilcliente/equipo/${this.props.clienteId}/${rowData.equipoid}`} style={{ color: '#03178C' }} ><ChevronRight  /></Link>,
            tooltip: 'Ver Perfil ',
            onClick: (rowData)
          })
          ,
                
          rowData => ({
            icon: tableIcons.Add,
            tooltip: 'Realizar Calibración',
            onClick: (event, rowData) => {
              this.handleOpen(rowData.progid,rowData.equipoid,rowData.fecha,rowData.hora,rowData.idinventario);
            },
            disabled: rowData.estado==='Realizado'
          })
         ,
            
            {
              icon: tableIcons.Delete,
              tooltip: 'Borrar Programación',
              onClick: (event, rowData) => {
                  
               this.handleOpenDelete(rowData.progid,rowData.equipoid,rowData.clienteid);
              }
            }
            
          ]}
        >

        </MaterialTable>

      
        </div>
        ):(
        <div className="div_cargando">
           <CircularProgress size={50}color="primary" className="login-progress" />
        </div>
        )


        return (
            <div>
                {mapeoequipos}
                <Dialog
             open={this.state.open}
             onClose={this.handleClose}
             fullWidth
            maxWidth="sm"
            >
              {this.state.aprobado.mensaje ? (
              
              <div className='div-aprobado'>
                  <div className='div-icono-aprobado'>
                    <FaCheck color="#ffffff" className='icono-aprobado'/>
                  </div>
                  <h2 className='text-aprobado'>Reporte Cargado Exitosamente</h2>
              </div>
                
                ) : (<div>  
                  <DialogTitle>Realizar Calibración</DialogTitle>
                <DialogContent>
            <form>
            <div className="div-accordion-container">
          <TextField className="login-input-email"
                      required
                      type="date"
                      name="fecha"
                      id="fecha"
                      label="Fecha de Realización"
                      value={this.state.fecha}
                      onChange={this.handleChange}
                      min="2019-01-01" max="2022-01-01"
                      fullWidth
                      helperText={errors.fecha}
                      error={errors.fecha?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
          <div className="div-input-doble-mtoprog">
          <TextField className="login-input-email"
                      required
                      type="time"
                      name="horainicio"
                      id="horainicio"
                      label="Hora de Inicio"
                      value={this.state.horainicio}
                      onChange={this.handleChange}
                      min="2000-01-01" max="2022-01-01"
                      fullWidth
                      helperText={errors.horainicio}
                      error={errors.horainicio?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
            <TextField className="login-input-email"
                      required
                      type="time"
                      name="horafinal"
                      id="horafinal"
                      label="Hora Final"
                      value={this.state.horafinal}
                      onChange={this.handleChange}
                      min="2000-01-01" max="2022-01-01"
                      fullWidth
                      helperText={errors.horafinal}
                      error={errors.horafinal?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
        
            </div>

            <TextField className="login-input-email"
                      required
                      type="text"
                      name="numcertificado"
                      id="numcertificado"
                      label="Numero del Certificado"
                      value={this.state.numcertificado}
                      onChange={this.handleChange}
                      fullWidth
                      helperText={errors.numcertificado}
                      error={errors.numcertificado?true:false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      />
          </div>
 
 
       
            </form>
      
  


           
              
            </DialogContent>
            </div>)}
            
            

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>

             {loading ? (
                <CircularProgress size={30} color="primary" className="login-progress" />
              ) : (
                this.state.aprobado.mensaje ? (
                  <MyButton tip="Continuar" onClick={this.handleClose}>
                    <FaCheckCircle color="#20B90C" className="icon-formulario" />
                  </MyButton>
                ) : (
                  <MyButton tip="Subir Reporte de Calibración" onClick={this.handlePostCal}>
                    <FaPlus color="#03178C" className="icon-formulario" />
                  </MyButton>
                )
              )}
           
           </DialogActions>
            </Dialog>



                <Dialog
             open={this.state.opendelete}
             onClose={this.handleCloseDelete}
             fullWidth
            maxWidth="sm"
            >
            <DialogTitle>Estas seguro de borrar programación?</DialogTitle>

            <DialogActions>
            <MyButton tip="Cerrar" onClick={this.handleClose}>
            <FaArrowLeft color="#D3D7DC" className="icon-formulario"/>
             </MyButton>
             {loading ?  <CircularProgress size={30}color="primary" className="login-progress" />:
                          <MyButton tip="Borrar Programación" onClick={this.handleDelete}>
                          <FaPlus  color="#03178C" className="icon-formulario"/>
                           </MyButton>}
           </DialogActions>
            </Dialog>
            </div>
        )
    }
}



TablaCronogramaCal.propTypes={
    getListaCal:PropTypes.func.isRequired,
    getListaCalFiltrado:PropTypes.func.isRequired,
    data:PropTypes.object.isRequired,
    clienteId: PropTypes.string.isRequired,
    clearErrors:PropTypes.func.isRequired,
    UI:PropTypes.object.isRequired,
    deleteProgCalTabla:PropTypes.func.isRequired,
    editProgCal:PropTypes.func.isRequired,
    postCalibracionTabla:PropTypes.func.isRequired,
    user:PropTypes.object.isRequired,  
}

const mapActionsToProps={getListaCalFiltrado,getListaCal,clearErrors,deleteProgCalTabla, editProgCalTabla,postCalibracionTabla};
const mapStateToProps=state=>({
    data:state.data,
    user:state.user,
    UI:state.UI
})
export default connect(mapStateToProps,mapActionsToProps) (TablaCronogramaCal);

