import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import {getPerfil}from '../../../redux/actions/useractions';
import './MiPerfil.css';
import {FaCity,FaPhoneAlt,FaIdCard,FaMailBulk,FaLocationArrow,FaUserCheck} from "react-icons/fa";
import SubirFotoPerfil from './SubirFotoPerfil';

export class MiPerfil extends Component {
    componentDidMount(){
        this.props.getPerfil();
      }
  
   
    render() {
        const {credentials,loadingperfil}= this.props.user; 

        let cargarPerfil= !loadingperfil ? (
        
            
        <div className="div_container_perfil">
            <div className="div_card_perfil">
                <div className="div_img_perfil">
                <img src={credentials.imageUrl} alt="profile" className="perfil-image"/>
                <SubirFotoPerfil handleuser={credentials.handle}></SubirFotoPerfil>
               

                </div>
                <div className="div_card_content">
                    <div className="div_perfil_titulo">
                        <h1 className="div_nombre_perfil">{credentials.nombre}</h1>
                        <h3 className="div_nombre_handler">@{credentials.handle}</h3>
                        <hr className="sidebar-separador"></hr>
                   </div>

                   <div className="div_perfil_informacion">
                       <div className="div_perfil_infocolumna">
                       <div className="div_info_perfil" >
                        <FaIdCard color="#0468BF"className="perfil-icon"/>    
                        <h3 className="div_text_info_perfil">{credentials.ccnit}</h3>
                        </div>
                        <div className="div_info_perfil" >
                        <FaCity color="#0468BF"className="perfil-icon"/>    
                        <h3 className="div_text_info_perfil">{credentials.ciudad}</h3>
                        </div>
                        <div className="div_info_perfil" >
                        <FaUserCheck color="#0468BF"className="perfil-icon"/>    
                        <h3 className="div_text_info_perfil">{credentials.tipouser}</h3>
                        </div>
                       </div>

                       <div className="div_perfil_infocolumna">
                       <div className="div_info_perfil" >
                        <FaMailBulk color="#0468BF"className="perfil-icon"/>    
                        <h3 className="div_text_info_perfil">{credentials.email}</h3>
                        </div>
                        <div className="div_info_perfil" >
                        <FaLocationArrow color="#0468BF"className="perfil-icon"/>    
                        <h3 className="div_text_info_perfil">{credentials.direccion}</h3>
                        </div>
                       <div className="div_info_perfil" >
                        <FaPhoneAlt color="#0468BF"className="perfil-icon"/>    
                        <h3 className="div_text_info_perfil">{credentials.telefono}</h3>
                        </div>
                        </div>
                   </div>
                             

                    </div>

            </div>
            

            
        </div>
        
        ):(
            <div>
                      <div className="div_cargando"><CircularProgress size={50}color="primary" className="login-progress" /></div>
            </div>
        )

        return (
            <div>
               {cargarPerfil}
            </div>
        )
    }
}


MiPerfil.propTypes={
    getPerfil:PropTypes.func.isRequired,
    user:PropTypes.object.isRequired
}
const mapStateToProps=state=>({
    user:state.user
})
export default connect(mapStateToProps,{getPerfil}) (MiPerfil);

