import React from 'react';
import Button from '../../../Elements/Button';
import './Contacto.css'; 
import {FaPhoneAlt,FaMapSigns,FaMailBulk,FaFacebookF,FaYoutube,FaTwitter} from 'react-icons/fa';

function Contacto() {
    return (
        <div id="contacto">
            <div className="contact-container">
            <div className="form-divtitulo">
                            <h1 className="form-titulo">Ponte en Contacto</h1>
                            <hr className="form-separador"/>
                            <p className="form-descripcion">Utiliza este espacio para enviarnos un mensaje, solo debes contactarte a tráves de nuestros canales de atención y te responderemos lo más pronto.</p>
                        </div>
            <h3 className="contact-tituloinfo">Información</h3>
                <div className="info-container">
                    <div className="contact-item">
                        
                        <p className="contact-pitem">
                            <span className="contact-spanitem">
                                <FaMapSigns className="contacticon"/>
                                Dirección
                            </span>
                            Cali,Colombia
                        </p>

                    </div>
                    <div className="contact-item">
                        <p className="contact-pitem">
                            <span className="contact-spanitem">
                                <FaPhoneAlt className="contacticon"/>
                                Teléfono
                            </span>
                            +57-3218472298
                        </p>

                    </div>
                    <div className="contact-item">
                        <p className="contact-pitem">
                            <span className="contact-spanitem">
                                <FaMailBulk className="contacticon"/>
                                E-mail
                            </span>
                           gerencia@alphabiomedica.com
                        </p>

                    </div>

                </div>

                

                </div>
                <div className="contact-socialcontainer">
                    <div className="contact-socialrow">
                        <ul className="contact-ul">
                            <li className="contact-li">
                            <div className="contact-bordersocial">
                                <a href="http://www.facebook.com/" target="blank">
                                    <FaFacebookF className="contact-socialicon"/>
                                </a>
                                </div>
                            </li>
                            <li className="contact-li">
                                <div className="contact-bordersocial">
                                <a href="http://www.youtube.com/" target="blank">
                                    <FaYoutube className="contact-socialicon"/>
                                </a>
                                </div>
                            </li>
                            <li className="contact-li">
                                <div className="contact-bordersocial">
                                <a href="http://www.twitter.com/" target="blank">
                                    <FaTwitter className="contact-socialicon"/>
                                </a>
                                </div>
                               
                            </li>
                            
                        </ul>

                    </div>

            </div>
            <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2020 Plataforma Web Diseñada por Bioinnova Technologies S.A.S. Todos los derechos reservados{" "}
              
            </p>
          </div>
        </div>
            
        </div>
    )
}

export default Contacto
