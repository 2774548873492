import React from 'react';
import './About.css';
import imgsrc from './aboutimg.jpg'
function About() {
    return (
        <div id="about">
            <div className="container-about">
                <div className="row">
                    <div className="col">
                    <img src={imgsrc} alt="imgab" className='imgabout'/>
                    </div>
                    <div className="col">
                        <div className="divdescription">
                            <h1 className="titudescription">Más Sobre Nosotros</h1>
                            <hr className="separadorgradienteabout"/>
                            <p className="paradescription">Alpha Biomédica es un ecosistema digital de ingeniería clínica, que brinda herramientas inteligentes para la gestión de tecnología médica.  Nuestra misión es generar un espacio de trabajo digital que integre a profesionales en ingeniería clínica, profesionales en salud, hospitales, consultorios y prooveedores para mejorar la gestión de tecnología y seguir avanzando hacia el futuro de esta.</p>
                            <h2 className="subdescription">Porque usar nuestra plataforma?</h2>
                            <div className="rowlista">
                                <div className="collista">
                                <ul className="ulabout">
                                    <li className="liabout">Olvidate de Documentos en Excel.</li>
                                    <li className="liabout">Accede a Marketplace de equipos.</li>
                                    <li className="liabout">Integra IA a tus Procesos</li>
                                    <li className="liabout">Gestiona con indicadores</li>
                                
                                </ul>
                                </div>
                                <div className="collista">
                                <ul className="ulabout">
                                    <li className="liabout">Haz parte de una comunidad.</li>
                                    <li className="liabout">Forma equipos de trabajo.</li>
                                    <li className="liabout">Podrás capacitarte</li>
                                    <li className="liabout">Administra mejor tus recursos.</li>
                                    
                                </ul>
                                </div>

                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About
